const WorkshopAbout = () => {
  return (
    <div className="h-full tracking-wide text-gray-700">
      <h2 className="text-xl font-medium md:text-2xl">
        Talleres Telemáticos en Salud Mental:
      </h2>
      <hr className="mt-2 mb-4" />
      <p className="mb-2 text-justify">
        - Se espera recibir propuestas de talleres orientados al trabajo de
        promoción, prevención o intervención en temáticas de salud mental en
        modalidad telemática.
      </p>
      <p className="mb-2 text-justify">
        - Las propuestas seleccionadas deberán ser llevadas a cabo a través de
        la plataforma Zoom durante las Jornadas.
      </p>
      <p className="mb-2 text-justify">
        - Previa autorización de los participantes, los talleres podrán ser
        grabados y luego difundidos en las redes sociales de la Facultad de
        Medicina de la Universidad Austral de Chile.
      </p>
      <p className="mb-2 text-justify">
        - Los talleres tendrán una duración máxima de 60 minutos.
      </p>
      <p className="mb-2 text-justify text-primary-500">
        - Enviar el formulario completo a crps@uach.cl. antes del 28 de octubre
        2021.
      </p>
    </div>
  );
};

export default WorkshopAbout;
