import ScheduleDay from "./ScheduleDay";
import { dayOne, dayTwo } from "../../shared/constants";

const Schedule = ({ scrollRef }) => {
	return (
		<div className="py-10 md:px-5 tracking-wide container mx-auto" ref={scrollRef}>
			<div className="flex justify-center items-center flex-col mb-5">
				<h1 className="text-primary-500 font-medium text-2xl md:text-4xl text-center my-2">Horarios del Evento</h1>
				<span className="text-gray-500 font-light text-xs md:text-sm text-center">Los siguientes horarios abarcan los 2 días de presentaciones</span>
			</div>
			<div className="grid md:gap-10 md:grid-cols-2 px-2 md:px-5">
				<ScheduleDay day={1} eventDate="11 de Noviembre, 2021" program={dayOne}/>
				<ScheduleDay day={2} eventDate="12 de Noviembre, 2021" program={dayTwo}/>
			</div>
		</div>
	);
};

export default Schedule;

