import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { clean, format } from "rut.js";

const useValidation = ({ initialState, validate, fn }) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [submitForm, setSubmitForm] = useState(false);

  useEffect(() => {
    if (submitForm) {
      // const noErrors = Object.keys(errors).length === 0;
      const noErrors =
        JSON.stringify(errors) ===
        JSON.stringify({
          authors: [],
          presenters: [],
        });
      if (noErrors) {
        fn(); // Fn = Función que se ejecuta en el componente
      }
      setSubmitForm(false);
    }
  }, [errors, fn, submitForm]);

  // Función que se ejecuta conforme el usuario escribe algo
  const handleChange = (e) => {
    const target = e.target;
    const value = target?.type === "checkbox" ? target?.checked : target?.value;
    const name = target.name;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeOthers = (name, value) => {
    let _value = value;
    const withRut = name.includes("rut");
    if (withRut) {
      _value = clean(_value);
      _value = format(_value);
    }
    setValues({
      ...values,
      [name]: withRut ? format(_value) : _value,
    });
  };

  const resetForm = () => {
    // document.getElementById("form").reset();
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setValues({});
    setValues(initialState);
  };

  // Función que se ejecuta cuando el usuario hace submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors({ ...validationErrors });
    setSubmitForm(true);
    e.target.reset();
  };

  // cuando se realiza el evento de blur
  const handleBlur = () => {
    // const validationErrors = validate(values);
    // setErrors(validationErrors);
  };

  const handleDynamicChange = (parentName, i, name, value) => {
    let _value = value;
    const withRut = name.includes("rut");
    if (withRut) {
      _value = clean(_value);
      _value = format(_value);
    }
    let newFormValues = [...values[parentName]];
    newFormValues[i][name] = withRut ? format(_value) : _value;
    setValues({
      ...values,
      [parentName]: newFormValues,
    });
  };

  const addFormFields = (name, newFields) => {
    if (values[name].length <= 2) {
      setValues({
        ...values,
        [name]: [...values[name], ...[newFields]],
      });
    }
  };

  const removeFormFields = (name, i, valuesToRemove) => {
    let newFormValues = [...valuesToRemove];
    newFormValues.splice(i, 1);
    setValues({
      ...values,
      [name]: newFormValues,
    });
  };

  return {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleChangeOthers,
    handleBlur,
    resetForm,
    handleDynamicChange,
    addFormFields,
    removeFormFields,
  };
};

useValidation.propTypes = {
  initialState: PropTypes.obj,
  validate: PropTypes.func,
  fn: PropTypes.func,
};
useValidation.defaultProps = {
  initialState: {},
  validate: () => {},
  fn: () => {},
};
export default useValidation;
