import LazyLoad from "react-lazyload";
import { CSSTransition } from "react-transition-group";
import Image from "../../assets/img/crps-9.jpeg";

const WhereWhen = () => {
  return (
    <LazyLoad throttle={200} height={300}>
      <CSSTransition key={"WhereWhen"} in={true} timeout={500} classNames="item">
        <div className="grid grid-cols-1 bg-gray-100 md:grid-cols-2">
          <div
            className="w-full bg-local bg-center bg-no-repeat bg-cover h-96"
            style={{
              backgroundImage: `url(${Image})`,
            }}
          >
            <div
              className="z-10 flex items-center justify-center w-full h-full"
              style={{
                background: "rgba(0, 0, 0, 0.3)",
              }}
            ></div>
          </div>
          <div className="flex flex-col items-center justify-center px-4 py-8 md:px-8 md:py-0">
            <div className="w-full mb-5">
              <ul className="list-inside font-light">
                <li>
                  <h3 className="mb-2 text-2xl font-medium lg:text-4xl text-primary-500">¿Dónde?</h3>
                </li>
                <li>
                  Transmisión vía Zoom y canal youtube Facultad de Medicina UACh
                  y CRPs
                </li>
              </ul>
            </div>
            <div className="w-full">
              <ul className="list-inside font-light">
                <li>
                  <h3 className="mb-2 text-2xl font-medium lg:text-4xl text-primary-500">¿Cuándo?</h3>
                </li>
                <li>11 y 12 de noviembre 2021</li>
              </ul>
            </div>
          </div>
        </div>
      </CSSTransition>
    </LazyLoad>
  );
};

export default WhereWhen;
