import { FaFacebookSquare } from "components/Icons";
import LogoCRPS from "assets/img/Logo-CRPS.png";
import { Logo } from "components";

const Footer = () => {
  return (
    <footer className="bottom-0 w-full bg-gray-800">
      <div className="px-5 py-5 md:px-20">
        <div className="container flex flex-col items-center h-full text-gray-300 md:flex-row">
          <Logo
            logo={LogoCRPS}
            alt="crps"
            containerClassName="w-20 md:mr-5 mb-3 md:mb-0"
            logoClassName="w-full"
          />
          <span className="text-center md:text-left md:text-xl font-light">
            CRPs - Centro de Rehabilitación Psicosocial - Facultad de Medicina
          </span>
        </div>
      </div>
      <div className="px-20">
        <hr className="border-gray-500 border-1" />
      </div>
      <div className="flex items-center justify-center px-3 pt-5">
        <a target="__blank" href="https://es-la.facebook.com/crpsvaldivia/">
          <span className="sr-only">Facebook</span>
          <FaFacebookSquare className="text-2xl text-gray-300 hover:text-gray-400" />
        </a>
      </div>
      <div className="h-20 px-3 md:h-16">
        <div className="container flex items-center justify-center h-full mx-auto">
          <span className="text-sm text-center text-gray-300">
            <a href="mailto:mujd19@gmail.com" className="hover:text-gray-400">
              Creado por mujd
            </a>{" "}
            &copy; {new Date().getFullYear()} CRPs y UACh Valdivia, Todos los
            derechos reservados.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
// social
// @crpsvaldivia -> facebook
