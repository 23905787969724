import PropTypes from "prop-types";

const Timer = ({ time, timeText, containerClassName }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center rounded-lg h-20 w-20 sm:h-28 sm:w-28 xl:h-36 xl:w-36 bg-primary-500 ${containerClassName}`}
    >
      <span className="mb-1 text-4xl font-medium sm:text-5xl xl:text-6xl">
        {time}
      </span>
      <span className="text-xs sm:text-sm">{timeText}</span>
    </div>
  );
};

Timer.propTypes = {
  time: PropTypes.number,
  timeText: PropTypes.string,
  containerClassName: PropTypes.string,
};
Timer.defaultProps = {
  time: 0,
  timeText: "Semanas",
  containerClassName: "",
};

export default Timer;
