import PropTypes from "prop-types";
const RadioButton = ({
  label,
  name,
  value,
  valueProp,
  containerClassName,
  onChange,
}) => {
  return (
    <div
      className={`flex items-center ${
        containerClassName && containerClassName
      }`}
    >
      <label className="w-full p-1 text-sm font-normal text-gray-700">
        <input
          id={name}
          name={name}
          type="radio"
          className="w-4 h-4 mr-3 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          onChange={() => onChange(name, value)}
          checked={valueProp === value}
        />
        <span className="text-right">{label}</span>
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  containerClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  valueProp: PropTypes.string,
};
RadioButton.defaultProps = {
  containerClassName: "",
  label: "",
  name: "",
  onChange: () => {},
  valueProp: "",
};

export default RadioButton;

// thematic_promo: false,
// thematic_prevent: false,
// thematic_approach: false,
// thematic_others: false,
