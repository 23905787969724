import LogoCRPS from "assets/img/Logo-CRPS.png";
import LogoUach from "assets/img/logo-uach.png";
import { HeroImage, Logo } from "components";

const Header = ({ scrollRef }) => {
  const executeScroll = () => scrollRef.current.scrollIntoView();

  return (
    <header>
      <Logo
        logo={LogoUach}
        alt="uach"
        containerClassName="absolute left-3 top-6 w-24 sm:w-28 md:left-5 md:top-10 md:w-44"
        logoClassName="w-full rounded-xl"
      />
      <Logo
        logo={LogoCRPS}
        alt="crps"
        containerClassName="absolute right-3 top-3 w-24 sm:w-28 md:left-48 md:top-5 md:w-44 ml-5"
        logoClassName="w-full"
      />
      <HeroImage scrollAction={executeScroll} />
    </header>
  );
};

export default Header;
