import { validate } from "rut.js";

export default function ValidateSystematizationExpositorForm(values) {
  let errors = {
    authors: [],
    presenters: [],
  };

  // STEP 1
  if (!values.thematic_title) {
    errors.thematic_title = "La título es obligatorio";
  }
  if (!values.thematic) {
    errors.thematic = "La tematica es obligatoria";
  }
  if (!values.thematic_type) {
    errors.thematic_type = "La categoría de la experiencia es obligatoria";
  }
  if (values.thematic_type === "others" && !values.thematic_other_type) {
    errors.thematic_other_type = `Si seleccionaste "Otras temáticas", este campo es obligatorio`;
  }
  if (!values.thematic_key_words) {
    errors.thematic_key_words = "Las palabras clave son obligatorias";
  } else if (
    values.thematic_key_words.split(",").length < 3 ||
    values.thematic_key_words.split(",").length > 5
  ) {
    errors.thematic_key_words =
      "Solo puedes agregar entre 3 y 5 palabras clave separadas por coma";
  }
  // STEP 2
  if (!values.institution_name) {
    errors.institution_name = "El nombre de la institución es obligatorio";
  }
  if (!values.institution_country) {
    errors.institution_country = "El país de la institución es obligatorio";
  }
  if (!values.institution_region) {
    errors.institution_region = "La región de la institución es obligatoria";
  }
  if (!values.institution_commune) {
    errors.institution_commune = "La comuna de la institución es obligatoria";
  }

  values.authors.map((author, i) => {
    let author_fullname;
    let author_email;
    let author_phone;
    if (!author.author_fullname) {
      author_fullname = `El Nombre del autor/autora ${
        values.authors.length === 1 ? "" : i + 1
      } es obligatorio`;
      errors.authors.push({ id: i + 1, author_fullname });
    }
    if (!author.author_email) {
      author_email = `El Email del autor/autora ${
        values.authors.length === 1 ? "" : i + 1
      } es obligatorio`;
      errors.authors.push({ id: i + 1, author_email });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(author.author_email)
    ) {
      author_email = `El Email del autor/autora ${
        values.authors.length === 1 ? "" : i + 1
      } no es válido`;
      errors.authors.push({ id: i + 1, author_email });
    }
    if (!author.author_phone) {
      author_phone = `El teléfono del autor/autora ${
        values.authors.length === 1 ? "" : i + 1
      } es obligatorio`;
      errors.authors.push({ id: i + 1, author_phone });
    } else if (!/^\d*\.?\d*$/.test(author.author_phone)) {
      author_phone = `El teléfono del autor/autora ${
        values.authors.length === 1 ? "" : i + 1
      } debe ser de 9 dígitos. Ejemplo: 971232172`;
      errors.authors.push({ id: i + 1, author_phone });
    } else if (author.author_phone.length !== 9) {
      author_phone = `El teléfono del autor/autora ${
        values.authors.length === 1 ? "" : i + 1
      } debe ser de 9 dígitos. Ejemplo: 971232172`;
      errors.authors.push({ id: i + 1, author_phone });
    }
    return author;
  });
  // STEP 3
  // experience_performed_activities: "",
  // experience_results: "",
  if (!values.experience_objective) {
    errors.experience_objective =
      "El objetivo de la experiencia es obligatorio";
  }
  if (!values.experience_objective_group) {
    errors.experience_objective_group =
      "El grupo objetivo de la experiencia es obligatorio";
  }
  if (!values.experience_life_cycle) {
    errors.experience_life_cycle =
      "El ciclo vital de la experiencia es obligatorio";
  }
  if (!values.experience_number_of_participants) {
    errors.experience_number_of_participants =
      "El número de participantes de la experiencia es obligatorio";
  }
  if (!values.experience_date_start) {
    errors.experience_date_start =
      "La fecha de inicio de la experiencia es obligatoria";
  }
  if (!values.experience_date_end) {
    errors.experience_date_end =
      "La fecha de termino de la experiencia es obligatoria";
  }
  if (values.experience_date_start && values.experience_date_end) {
    if (
      values.experience_date_start?.getTime() >
      values.experience_date_end?.getTime()
    ) {
      errors.experience_date_start =
        "La fecha de inicio no puede ser mayor a la fecha de termino";
      errors.experience_date_end =
        "La fecha de termino no puede ser menor a la fecha de inicio";
    }
  }
  if (!values.experience_performed_activities) {
    errors.experience_performed_activities =
      "Las actividades realizadas en la experiencia son obligatorias";
  }
  if (!values.experience_results) {
    errors.experience_results =
      "Los resultados de la experiencia son obligatorios";
  }

  // STEP4
  values.presenters.map((presenter, i) => {
    let presenter_rut;
    let presenter_email;
    let presenter_fullname;
    let presenter_phone;
    let presenter_curricular_background;
    if (!presenter.presenter_rut) {
      presenter_rut = `El RUT del presentador ${
        values.presenters.length === 1 ? "" : i + 1
      } es obligatorio`;
      errors.presenters.push({ id: i + 1, presenter_rut });
    } else if (!validate(presenter.presenter_rut)) {
      presenter_rut = `El RUT del presentador ${
        values.presenters.length === 1 ? "" : i + 1
      } no es válido`;
      errors.presenters.push({ id: i + 1, presenter_rut });
    }
    if (!presenter.presenter_email) {
      presenter_email = `El Email del presentador ${
        values.presenters.length === 1 ? "" : i + 1
      } es obligatorio`;
      errors.presenters.push({ id: i + 1, presenter_email });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
        presenter.presenter_email
      )
    ) {
      presenter_email = `El Email del presentador ${
        values.presenters.length === 1 ? "" : i + 1
      } no es válido`;
      errors.presenters.push({ id: i + 1, presenter_email });
    }
    if (!presenter.presenter_fullname) {
      presenter_fullname = `El Nombre del presentador ${
        values.presenters.length === 1 ? "" : i + 1
      } es obligatorio`;
      errors.presenters.push({ id: i + 1, presenter_fullname });
    }
    if (!presenter.presenter_phone) {
      presenter_phone = `El teléfono del presentador ${
        values.presenters.length === 1 ? "" : i + 1
      } es obligatorio`;
      errors.presenters.push({ id: i + 1, presenter_phone });
    } else if (!/^\d*\.?\d*$/.test(presenter.presenter_phone)) {
      presenter_phone = `El teléfono del presentador ${
        values.presenters.length === 1 ? "" : i + 1
      } debe ser de 9 dígitos. Ejemplo: 971232172`;
      errors.presenters.push({ id: i + 1, presenter_phone });
    } else if (presenter.presenter_phone.length !== 9) {
      presenter_phone = `El teléfono del presentador ${
        values.presenters.length === 1 ? "" : i + 1
      } debe ser de 9 dígitos. Ejemplo: 971232172`;
      errors.presenters.push({ id: i + 1, presenter_phone });
    }
    if (!presenter.presenter_curricular_background) {
      presenter_curricular_background = `Los antecedentes curriculares del presentador ${
        values.presenters.length === 1 ? "" : i + 1
      } son obligatorios`;
      errors.presenters.push({ id: i + 1, presenter_curricular_background });
    }
    return presenter;
  });

  return errors;
}
