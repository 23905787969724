import { useRef, useState } from "react";
import { FormButtons } from "components";
import CommonStep from "../FormSteps/CommonStep";
import useValidation from "hooks/useValidation";
import validateAssistantForm from "validations/validateSystematizationAssistantForm";
import { useFirebaseContext } from "firebase";
import { clean } from "rut.js";
import useNotification from "hooks/useNotification";

const SystematizationAssistantForm = ({ type }) => {
  const [rutLoading, setRutLoading] = useState(false);
  const formRef = useRef();
  const { toast } = useNotification({
    message: "Inscripción enviada correctamente!",
    icon: "success",
  });
  const { swal } = useNotification({
    title: "Lo sentimos",
    message:
      "El rut ingresado ya esta registrado. Si tienes dudas o consultas escribenos a crps@uach.cl",
    type: "info",
  });
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleChangeOthers,
    handleBlur,
    resetForm,
  } = useValidation({
    initialState: { ...default_values, type },
    validate: validateAssistantForm,
    fn: handleIfExists,
  });
  const {
    firebase,
    state: { isFormLoading },
    changeItem,
  } = useFirebaseContext();
  async function handleIfExists() {
    setRutLoading(true);
    try {
      const _value = values.rut;
      const result = await firebase.db
        .collection(type)
        .where("rut", "==", clean(_value))
        .get();
      if (result.docs.length >= 1) {
        swal();
      } else {
        createCollection();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRutLoading(false);
    }
  }
  async function createCollection() {
    changeItem({ isFormLoading: true });
    try {
      const collection = {
        ...values,
        rut: clean(values.rut),
        phone: `+56${values.phone}`,
        createdAt: Date.now(),
      };
      await firebase.db.collection(type).add(collection);
      toast.fire();
      resetForm();
      formRef.current.reset();
      // setTimeout(() => {
      //   go(0);
      // }, 1500);
    } catch (error) {
      console.log(error);
    } finally {
      changeItem({ isFormLoading: false });
    }
  }
  const isLoading = rutLoading || isFormLoading;
  return (
    <form id="form" ref={formRef} onSubmit={handleSubmit} noValidate>
      <CommonStep
        onChange={handleChange}
        handleChange={handleChangeOthers}
        onBlur={handleBlur}
        values={values}
        errors={errors}
      />
      <FormButtons isLoading={isLoading} />
    </form>
  );
};

export default SystematizationAssistantForm;

const default_values = {
  name: "",
  lastNames: "",
  rut: "",
  email: "",
  phone: "",
};
