import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BiCalendarAlt } from "../Icons";
import FcClock from "../Icons/FcClock";

const ScheduleDay = ({ day, eventDate, program }) => {
	const total = program?.length;
	return (
		<div className="shadow-lg mb-2">
			<div className="bg-indigo-700 text-white flex flex-row items-baseline p-5 rounded-t-md">
				<BiCalendarAlt className="text-lg"/>
				<h2 className="mx-3 font-medium text-lg">Día {day}</h2>
				<span className="text-xs font-light">{eventDate}</span>
			</div>
			{
				program?.map((p, i) => (
					<Fragment key={i}>
						<div className="py-2 px-10 h-28 text-gray-900">
							<div className="flex flex-col justify-center h-full">
								<span className="text-xs font-light text-gray-500 mb-1">{p.time}</span>
								<div className="flex flex-row items-center">
									{p.icon && <FcClock className="mr-2 text-2xl"/>}<span
									className={`${p.isBold ? "font-medium" : "font-light"}`}>{p.program}</span>
								</div>
							</div>
						</div>
						{total > 1 && total !== i + 1 && (
							<hr/>
						)}
					</Fragment>
				))
			}
		</div>
	);
};

ScheduleDay.propTypes = {
	day: PropTypes.number,
	eventDate: PropTypes.string,
	program: PropTypes.array,
};

export default ScheduleDay;

