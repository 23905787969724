import { useRef } from "react";
import ThematicStep from "../FormSteps/ThematicStep";
import InstitutionStep from "../FormSteps/InstitutionStep";
import ExperienceStep from "../FormSteps/ExperienceStep";
import PresenterStep from "../FormSteps/PresenterStep";
import { FormButtons } from "components";
import ValidateSystematizationExpositorForm from "validations/ValidateSystematizationExpositorForm";
import { useFirebaseContext } from "firebase";
import useValidation from "hooks/useValidation";
import useNotification from "hooks/useNotification";
import { useHistory } from "react-router-dom";

const SystematizationExpositorForm = ({ type }) => {
  const { go } = useHistory();
  const formRef = useRef();
  const { toast } = useNotification({
    message: "Inscripción enviada correctamente!",
    icon: "success",
  });
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleChangeOthers,
    // handleBlur,
    resetForm,
    handleDynamicChange,
    addFormFields,
    removeFormFields,
  } = useValidation({
    initialState: { ...default_values, type },
    validate: ValidateSystematizationExpositorForm,
    fn: createCollection,
  });
  const {
    firebase,
    state: { isFormLoading },
    changeItem,
  } = useFirebaseContext();
  async function createCollection() {
    changeItem({ isFormLoading: true });
    try {
      const collection = {
        ...values,
        thematic_other_type: values.thematic_other_type
          ? values.thematic_other_type
          : null,
        createdAt: Date.now(),
      };
      await firebase.db.collection(type).add(collection);
      toast.fire();
      resetForm();
      formRef.current.reset();
      setTimeout(() => {
        go(0);
      }, 1500);
    } catch (error) {
      console.log(error);
    } finally {
      changeItem({ isFormLoading: false });
    }
  }

  return (
    <form id="form" ref={formRef} onSubmit={handleSubmit} noValidate>
      <hr className="mt-3 mb-2" />
      {/* STEP 1 */}
      <ThematicStep
        handleChangeOthers={handleChangeOthers}
        values={values}
        handleChange={handleChange}
        errors={errors}
        type={type}
      />
      <hr className="mt-3 mb-2" />
      {/* STEP 2 */}
      <InstitutionStep
        handleChange={handleChange}
        values={values}
        errors={errors}
        handleDynamicChange={handleDynamicChange}
        addFormFields={addFormFields}
        removeFormFields={removeFormFields}
      />
      <hr className="mt-3 mb-2" />
      {/* STEP 3 */}
      <ExperienceStep
        onChange={handleChange}
        handleChange={handleChangeOthers}
        values={values}
        errors={errors}
      />
      <hr className="mt-3 mb-2" />
      {/* STEP 4 */}
      <PresenterStep
        values={values}
        errors={errors}
        type={type}
        handleDynamicChange={handleDynamicChange}
        addFormFields={addFormFields}
        removeFormFields={removeFormFields}
      />
      <FormButtons isLoading={isFormLoading} />
    </form>
  );
};

export default SystematizationExpositorForm;

const default_values = {
  // 1
  thematic_title: "",
  thematic: "",
  thematic_type: "",
  thematic_other_type: null,
  thematic_key_words: "",
  // 2
  institution_name: "",
  institution_country: "",
  institution_region: "",
  institution_commune: "",
  institution_address: "",
  authors: [
    {
      author_fullname: "",
      author_email: "",
      author_phone: "",
    },
  ],
  // 3
  experience_objective: "",
  experience_objective_group: "",
  experience_life_cycle: "",
  experience_number_of_participants: "",
  experience_date_start: "",
  experience_date_end: "",
  experience_performed_activities: "",
  experience_results: "",
  // 4
  presenters: [
    {
      // presenter_id: 0,
      presenter_fullname: "",
      presenter_rut: "",
      presenter_email: "",
      presenter_phone: "",
      presenter_curricular_background: "",
    },
  ],
};
