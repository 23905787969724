import { AiOutlineClose, BiCalendarAlt } from "components/Icons";
import PropTypes from "prop-types";
import DatePicker from "react-date-picker";

const Datepicker = ({
  label,
  name,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  containerClassName,
}) => {
  const handleChange = (value) => {
    if (value instanceof Date) {
      onChange(name, value);
    } else if (value?.target) {
      onChange(value?.target?.name, value?.target?.value);
    } else {
      onChange(name, "");
    }
  };
  return (
    <div className={containerClassName}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <DatePicker
        name={name}
        className={`datepicker`}
        calendarClassName="datapicker-calendar"
        calendarIcon={<BiCalendarAlt className="text-xl" />}
        clearIcon={<AiOutlineClose className="text-xl" />}
        // monthPlaceholder=" "
        // yearPlaceholder=" "
        // dayPlaceholder=" "
        // format="dd-MM-y"
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />
      {error && <span className="mt-1 text-xs text-red-500">{error}</span>}
    </div>
  );
};

Datepicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  containerClassName: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};
Datepicker.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  description: "",
  value: "",
  containerClassName: "",
  error: "",
  onBlur: () => {},
  onChange: () => {},
};

export default Datepicker;
