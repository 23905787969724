import PropTypes from "prop-types";

const Checkbox = ({
  label,
  description,
  name,
  value,
  containerClassName,
  onChange,
  // ...rest
}) => {
  return (
    <div
      className={`flex items-start ${containerClassName && containerClassName}`}
    >
      <div className="flex items-center h-5">
        <input
          id={name}
          name={name}
          type="checkbox"
          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
          onChange={onChange}
          // defaultValue={value}
          // value={value}
          checked={value}
          // {...rest}
        />
      </div>
      <div className="ml-3 text-sm">
        {label && (
          <label htmlFor={name} className="font-medium text-gray-700">
            {label}
          </label>
        )}
        {description && <p className="text-gray-500">{description}</p>}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  containerClassName: PropTypes.string,
  onChange: PropTypes.func,
};
Checkbox.defaultProps = {
  description: "",
  name: "",
  label: "",
  value: false,
  containerClassName: "",
  onChange: () => {},
};

export default Checkbox;
