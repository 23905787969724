import { FormTitle, Input, RadioButton } from "components/UI";

const ThematicStep = ({
  handleChangeOthers,
  values,
  handleChange,
  errors,
  type,
}) => {
  return (
    <div className="p-2 rounded-lg lg:p-4 xl:p-6 bg-gray-50">
      <FormTitle
        title="1.- Aspectos generales de la propuesta"
        description="Selecciona 1 opción"
      />
      <div className="grid grid-cols-1 gap-1 md:gap-3 md:grid-cols-2">
        <Input
          label="Título"
          onChange={handleChange}
          name="thematic_title"
          value={values.thematic_title}
          error={errors.thematic_title}
        />
        <Input
          label="Temática"
          description="Temática que aborda o problema que intenta resolver la experiencia."
          onChange={handleChange}
          name="thematic"
          value={values.thematic}
          error={errors.thematic}
        />
      </div>
      <legend className="my-2 text-sm font-medium text-gray-800">
        - Selecciona la categoría que mejor representa la experiencia:
      </legend>
      <div className="grid grid-cols-1 gap-1 mb-2 md:gap-2 xl:gap-4 md:grid-cols-2">
        <RadioButton
          label={
            type === "systematization_exhibitor"
              ? "Experiencias de trabajo con comunidades u organizaciones"
              : "Promoción de la salud mental"
          }
          onChange={handleChangeOthers}
          name="thematic_type"
          value={
            type === "systematization_exhibitor"
              ? "Experiencias de trabajo con comunidades u organizaciones"
              : "Promoción de la salud mental"
          }
          valueProp={values.thematic_type}
        />
        <RadioButton
          label={
            type === "systematization_exhibitor"
              ? "Experiencias de trabajo grupal con usuarios de servicios de salud mental y/o familiares"
              : "Prevención de problemas de salud mental"
          }
          onChange={handleChangeOthers}
          name="thematic_type"
          value={
            type === "systematization_exhibitor"
              ? "Experiencias de trabajo grupal con usuarios de servicios de salud mental y/o familiares"
              : "Prevención de problemas de salud mental"
          }
          valueProp={values.thematic_type}
        />
        <RadioButton
          label={
            type === "systematization_exhibitor"
              ? "Experiencias de consultas de tele salud mental"
              : "Abordaje de problemas de salud mental"
          }
          onChange={handleChangeOthers}
          name="thematic_type"
          value={
            type === "systematization_exhibitor"
              ? "Experiencias de consultas de tele salud mental"
              : "Abordaje de problemas de salud mental"
          }
          valueProp={values.thematic_type}
        />
        <RadioButton
          label="Otras temáticas"
          containerClassName="mb-2"
          onChange={handleChangeOthers}
          name="thematic_type"
          value="Otras temáticas"
          valueProp={values.thematic_type}
        />
      </div>
      {errors.thematic_type && (
        <span className="text-xs text-red-500">{errors.thematic_type}</span>
      )}
      {values.thematic_type === "Otras temáticas" && (
        <Input
          key={values.thematic_type}
          label="Nombre de la temática"
          onChange={handleChange}
          name="thematic_other_type"
          value={values.thematic_other_type}
          error={errors.thematic_other_type}
          containerClassName="mb-2"
        />
      )}
      {/* <hr className="mt-3 mb-2" /> */}
      <Input
        label="Palabras Claves (entre 3 y 5)"
        description={`Separa las palabras con una coma.`}
        onChange={handleChange}
        name="thematic_key_words"
        value={values.thematic_key_words}
        error={errors.thematic_key_words}
      />
    </div>
  );
};

export default ThematicStep;
