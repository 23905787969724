import PropTypes from "prop-types";
import Swal from "sweetalert2";

const useNotification = ({ title, message, icon, type }) => {
  const toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    icon: icon,
    title: message,
    customClass: {
      title: "swal-font-medium",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const swal = () => {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton:
          "relative inline-flex items-center px-4 py-2 border border-indigo-300 text-sm font-medium bg-indigo-500 rounded-md text-gray-100 bg-white hover:bg-indigo-600",
        cancelButton:
          "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
      },
      buttonsStyling: false,
    });
    customSwal.fire(title, message, type ? type : null);
  };

  return { toast, swal };
};

useNotification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
};
useNotification.defaultProps = {
  title: "",
  message: "",
  icon: "",
  type: "info",
};
export default useNotification;

// customClass: {
//   container: '...',
//   popup: '...',
//   header: '...',
//   title: '...',
//   closeButton: '...',
//   icon: '...',
//   image: '...',
//   content: '...',
//   htmlContainer: '...',
//   input: '...',
//   inputLabel: '...',
//   validationMessage: '...',
//   actions: '...',
//   confirmButton: '...',
//   denyButton: '...',
//   cancelButton: '...',
//   loader: '...',
//   footer: '....'
// }
