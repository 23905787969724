import Modal from "react-modal";
import { SystematizationAbout, WorkshopAbout } from "components";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");
const ModalAbout = ({ modalIsOpen, setModalIsOpen, type }) => {
  // let subtitle;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#333";
  }

  function closeModal() {
    setModalIsOpen(false);
  }
  return (
    <Modal
      onAfterOpen={afterOpenModal}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      // style={customStyles}
      contentLabel="Example Modal"
      className="Modal"
      overlayClassName="modal-fondo"
    >
      <div className="w-11/12 p-5 mx-auto overflow-y-auto bg-white shadow-md rounded-2xl md:p-10 formsAbout">
        {type === "systematization_exhibitor" && <SystematizationAbout />}
        {type === "telematics_workshop_exhibitor" && <WorkshopAbout />}
        <div className="w-full">
          <button
            onClick={closeModal}
            className="w-full px-8 py-4 mt-5 tracking-wide text-white uppercase bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700"
          >
            Cerrar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAbout;

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
// };
