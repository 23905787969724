import { Fragment } from "react";
import { FormTitle, Input, RutInput, Textarea } from "components/UI";
import { AiOutlineUserAdd, HiOutlineUserRemove } from "components/Icons";

const PresenterStep = ({
  values,
  errors,
  type,
  handleDynamicChange,
  addFormFields,
  removeFormFields,
}) => {
  const handleNewFields = () => {
    addFormFields("presenters", {
      presenter_fullname: "",
      presenter_rut: "",
      presenter_email: "",
      presenter_phone: "",
      presenter_curricular_background: "",
    });
  };
  const stepTitle =
    type === "systematization_exhibitor"
      ? "4.- Identificación de quién presentará el trabajo de sistematización en caso de ser seleccionado:"
      : "4.- Identificación de quién llevará a cabo el taller en caso de ser seleccionado:";
  const totalPresenters = values?.presenters?.length;
  return (
    <div className="p-2 rounded-lg lg:p-4 xl:p-6 bg-gray-50">
      <FormTitle
        title={stepTitle}
        description="Si la presentación será realizada por más de una persona, puedes agregar hasta 3 presentadores."
      />
      <div className="flex justify-end mb-2">
        <button
          className="flex flex-row items-center justify-between px-3 py-2 text-xs tracking-wide text-green-700 bg-green-100 rounded-md shadow-sm hover:bg-green-200"
          type="button"
          onClick={handleNewFields}
        >
          <AiOutlineUserAdd className="mr-2 text-base" /> Agregar presentador
        </button>
      </div>
      {values?.presenters?.map((presenter, index) => (
        <Fragment key={index}>
          {totalPresenters > 1 ? (
            <span className="text-sm text-gray-800">{`- Presentador nº ${
              index + 1
            }:`}</span>
          ) : null}
          <div className="grid grid-cols-1 gap-1 md:gap-3 md:grid-cols-2 lg:grid-cols-3">
            <Input
              label="Nombre completo"
              onChange={handleDynamicChange}
              name="presenter_fullname"
              value={presenter.presenter_fullname}
              error={errors?.presenters?.map((p, i) =>
                p.id === index + 1 ? p.presenter_fullname : null
              )}
              index={index}
              parentName="presenters"
            />
            <RutInput
              label="Número cédula de identidad"
              onChange={handleDynamicChange}
              name="presenter_rut"
              value={presenter.presenter_rut}
              error={errors?.presenters?.map((p, i) =>
                p.id === index + 1 ? p.presenter_rut : null
              )}
              maxLength="12"
              index={index}
              parentName="presenters"
            />
            <Input
              label="Correo electrónico"
              type="email"
              onChange={handleDynamicChange}
              name="presenter_email"
              value={presenter.presenter_email}
              error={errors?.presenters?.map((p, i) =>
                p.id === index + 1 ? p.presenter_email : null
              )}
              index={index}
              parentName="presenters"
            />
            <Input
              label="Teléfono"
              onChange={handleDynamicChange}
              name="presenter_phone"
              prefix={<span>+56</span>}
              maxLength="9"
              value={presenter.presenter_phone}
              error={errors?.presenters?.map((p, i) =>
                p.id === index + 1 ? p.presenter_phone : null
              )}
              index={index}
              parentName="presenters"
            />
          </div>
          <Textarea
            containerClassName="mt-2"
            label="Breves antecedentes curriculares (profesión o cargo, tiempo de experiencia en la temática, otros)"
            onChange={handleDynamicChange}
            name="presenter_curricular_background"
            value={presenter.presenter_curricular_background}
            error={errors?.presenters?.map((p, i) =>
              p.id === index + 1 ? p.presenter_curricular_background : null
            )}
            index={index}
            parentName="presenters"
          />
          {index ? (
            <div
              className={`flex justify-start${
                totalPresenters === index + 1 ? " mt-3 mb-8" : " my-3"
              }`}
            >
              <button
                className="flex flex-row items-center justify-between px-3 py-2 text-xs tracking-wide text-red-700 bg-red-100 rounded-md shadow-sm hover:bg-red-200"
                type="button"
                onClick={() =>
                  removeFormFields("presenters", index, values?.presenters)
                }
              >
                <HiOutlineUserRemove className="mr-2 text-base" /> Remover
                presentador {index + 1}
              </button>
            </div>
          ) : null}
          {totalPresenters > 1 && totalPresenters !== index + 1 && (
            <hr className="mt-3 mb-2" />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default PresenterStep;
