import PropTypes from "prop-types";

const Select = ({
  containerClassName,
  label,
  name,
  description,
  onChange,
  options,
}) => {
  return (
    <div className={containerClassName}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <select
        id={name}
        name={name}
        autoComplete={name}
        onChange={onChange}
        className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        {options?.map((option) => (
          <option value={option.value} key={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {description && (
        <p className="mt-1 text-xs text-gray-500">{description}</p>
      )}
    </div>
  );
};

Select.propTypes = {
  containerClassName: PropTypes.any,
  description: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.any,
  options: PropTypes.array,
};
Select.defaultValues = {
  containerClassName: "",
  description: "",
  label: "",
  name: "",
  onChange: () => {},
  options: [{ value: "", name: "" }],
};

export default Select;
