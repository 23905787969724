import LazyLoad from "react-lazyload";
import { CSSTransition } from "react-transition-group";
import { BsEnvelope, BsPhone } from "components/Icons";
import Image1 from "../../assets/img/crps-6.jpeg";

const Inquiries = () => {
  return (
    <LazyLoad throttle={200} height={300}>
      <CSSTransition key={"Inquiries"} in={true} timeout={500} classNames="item">
        <div className="grid grid-cols-1 bg-gray-100 md:grid-cols-2">
          <div
            className="w-full bg-local bg-center bg-no-repeat bg-cover h-96 md:order-2"
            style={{
              backgroundImage: `url(${Image1})`,
            }}
          >
            <div
              className="z-10 flex items-center justify-center w-full h-full"
              style={{
                background: "rgba(0, 0, 0, 0.3)",
              }}
            ></div>
          </div>
          <div className="flex flex-col items-center justify-center py-8 md:py-0">
            <h3 className="text-4xl font-medium lg:text-6xl text-primary-500">Consultas</h3>
            <div className="flex flex-row items-center my-5 text-2xl lg:my-10 lg:text-4xl font-light">
              <BsPhone className="mr-2" />
              <a href="tel:+56632224699">+56 63 2 224699</a>
            </div>
            <div className="flex flex-row items-center text-2xl lg:text-4xl font-light">
              <BsEnvelope className="mr-2" />
              <a href="mailto:crps@uach.cl">crps@uach.cl</a>
            </div>
          </div>
        </div>
      </CSSTransition>
    </LazyLoad>
  );
};

export default Inquiries;
