import PropTypes from "prop-types";

const Input = ({
  label,
  name,
  type,
  placeholder,
  description,
  value,
  error,
  minLength,
  maxLength,
  onChange,
  onBlur,
  prefix,
  containerClassName,
  index,
  parentName,
  // ...rest
}) => {
  const handleChange = (e) => {
    if (index >= 0) {
      onChange(parentName, index, e.target.name, e.target.value);
    } else {
      onChange(e);
    }
  };
  return (
    <div className={containerClassName}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="relative mt-1 rounded-md shadow-sm">
        {prefix && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-sm pointer-events-none">
            {prefix}
          </div>
        )}
        <input
          type={type}
          name={name}
          id={name}
          className={`block w-full pr-2 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 ${
            prefix ? "pl-10" : ""
          } sm:text-sm`}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          minLength={minLength}
          maxLength={maxLength}
          // {...rest}
        />
        {/* ${error ? "focus:ring-red-500 focus:border-red-500" : ""} */}
        {/* <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="currency" className="sr-only">
            Currency
          </label>
          <select
            id="currency"
            name="currency"
            className="h-full py-0 pl-2 text-gray-500 bg-transparent border-transparent rounded-md focus:ring-indigo-500 focus:border-indigo-500 pr-7 sm:text-sm"
          >
            <option>USD</option>
            <option>CAD</option>
            <option>EUR</option>
          </select>
        </div> */}
      </div>
      {error && <span className="mt-1 text-xs text-red-500">{error}</span>}
      {description && !error && (
        <p className="mt-1 text-xs text-gray-500">{description}</p>
      )}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  minLength: PropTypes.string,
  maxLength: PropTypes.string,
  containerClassName: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  prefix: PropTypes.node,
  index: PropTypes.number,
  parentName: PropTypes.string,
};
Input.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  description: "",
  value: "",
  containerClassName: "",
  type: "text",
  // error: "",
  // maxLength: "250",
  onBlur: () => {},
  onChange: () => {},
  //   prefix: <span className="text-gray-500 sm:text-sm">$</span>
};

export default Input;
