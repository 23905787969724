import { validate } from "rut.js";

export default function validateSystematizationAssistantForm(values) {
  let errors = {
    authors: [],
    presenters: [],
  };
  // validate rut
  if (!values.rut) {
    errors.rut = "El RUT es Obligatorio";
  } else if (!validate(values.rut)) {
    errors.rut = "RUT no válido";
  }
  // validar el email
  if (!values.email) {
    errors.email = "El Email es Obligatorio";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Email no válido";
  }
  // Validate name
  if (!values.name) {
    errors.name = "El Nombre es obligatorio";
  }
  // Validate lastNames
  if (!values.lastNames) {
    errors.lastNames = "Los apellidos son obligatorios";
  }
  // Validate phone
  if (!values.phone) {
    errors.phone = "El teléfono es obligatorio";
  } else if (!/^\d*\.?\d*$/.test(values.phone)) {
    errors.phone =
      "Ingrese un teléfono válido de 9 dígitos. Ejemplo: 971232172";
  } else if (values.phone.length !== 9) {
    errors.phone =
      "Ingrese un teléfono válido de 9 dígitos. Ejemplo: 971232172";
  }
  // else if (
  //   // !/^(0?9)(\s?)[9876543]\d{7}$/i.test(value) // => 985145215 9 numeros
  //   !/^(\+?\+56)?(\s?)(0?9)(\s?)[9876543]\d{7}$/i.test(values.phone) // => +56985145215 12 numeros
  //   // !/^(\+?56)?(\s?)(0?9)(\s?)[9876543]\d{7}$/i.test(value) // => 56985145215 11 numeros
  // ) {
  //   errors.phone = "Ingrese un teléfono válido. Ejemplo: +56985214568";
  // }

  return errors;
}
