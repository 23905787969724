import PropTypes from "prop-types";
import { ImSpinner2 } from "components/Icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useHistory } from "react-router-dom";

const FormButtons = ({ isLoading }) => {
  const history = useHistory();
  const handleCancel = () => {
    confirmAlert({
      title: "¿Estás seguro/a?",
      message: "Perderas todos los datos que rellenaste.",
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom",
      buttons: [
        {
          label: "Si",
          onClick: () => history.push("/"),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };
  return (
    <div className="flex flex-row items-center justify-between mt-4">
      <button
        onClick={handleCancel}
        className="px-8 py-4 tracking-wide text-gray-700 uppercase bg-gray-100 rounded-md shadow-sm hover:bg-gray-200"
      >
        Volver
      </button>
      <button
        disabled={isLoading}
        type="submit"
        className="px-8 py-4 tracking-wide text-white uppercase bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 disabled:opacity-50"
      >
        {isLoading ? <Spinner text="Enviando" /> : "Enviar"}
      </button>
    </div>
  );
};

FormButtons.propTypes = {
  isLoading: PropTypes.bool,
};
FormButtons.defaultProp = {
  isLoading: false,
};

export default FormButtons;

const Spinner = ({ text }) => (
  <div className="flex flex-row items-center justify-center">
    <ImSpinner2 className="mr-3 text-xl animate-spin" />
    <span className="animate-pulse">{text}</span>
  </div>
);
