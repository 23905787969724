import { createContext, useContext, useState } from "react";
import firebase from "./firebase";

export const FirebaseContext = createContext(null);

const contextDefaultValue = {
  globalFormErrors: {},
  isFormLoading: false,
};

const FirebaseProvider = ({ children, initValue = contextDefaultValue }) => {
  const [state, setState] = useState(initValue);

  const changeItem = (item) => {
    setState((prevState) => ({ ...prevState, ...item }));
  };
  return (
    <FirebaseContext.Provider
      value={{
        state,
        changeItem,
        firebase,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
export const useFirebaseContext = () => {
  const firebaseContext = useContext(FirebaseContext);
  if (firebaseContext === undefined) {
    throw new Error(
      "useFirebaseContext must be used within a FirebaseContext.Provider"
    );
  }
  return firebaseContext;
};

export default FirebaseProvider;
// export const useFirebaseContext = () => useContext(FirebaseContext);
