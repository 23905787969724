import PropTypes from "prop-types";
import { ImSpinner2 } from "components/Icons";

const Loader = ({ text }) => {
  return (
    <div className="flex flex-col items-center justify-center text-gray-100 bg-gray-900 h-80">
      <ImSpinner2 className="mb-3 text-6xl animate-spin" />
      <span className="text-xl animate-pulse">{text}</span>
    </div>
  );
};

Loader.propTypes = {
  text: PropTypes.string,
};
Loader.defaultProps = {
  text: "Cargando",
};

export default Loader;
