import { FormTitle, Input, RutInput } from "components/UI";
import React from "react";

const CommonStep = ({ onChange, handleChange, onBlur, values, errors }) => {
  return (
    <div className="p-2 rounded-lg lg:p-4 xl:p-6 bg-gray-50">
      <FormTitle title="Asistente a sistematización" />
      <div className="grid grid-cols-1 gap-1 md:gap-3 md:grid-cols-2">
        <Input
          label="Nombre"
          onChange={onChange}
          onBlur={onBlur}
          name="name"
          value={values.name}
          error={errors.name}
        />
        <Input
          label="Apellidos"
          onChange={onChange}
          onBlur={onBlur}
          name="lastNames"
          value={values.lastNames}
          error={errors.lastNames}
        />
        <RutInput
          label="Número cédula de identidad"
          onChange={handleChange}
          onBlur={onBlur}
          name="rut"
          value={values.rut}
          error={errors.rut}
          maxLength="12"
        />
        <Input
          label="Correo electrónico"
          type="email"
          onChange={onChange}
          onBlur={onBlur}
          name="email"
          value={values.email}
          error={errors.email}
        />
        <Input
          label="Teléfono"
          onChange={onChange}
          onBlur={onBlur}
          name="phone"
          prefix={<span>+56</span>}
          value={values.phone}
          error={errors.phone}
          maxLength="9"
        />
      </div>
    </div>
  );
};

export default CommonStep;
