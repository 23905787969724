import PropTypes from "prop-types";

const InputNumber = ({
  label,
  name,
  placeholder,
  description,
  value,
  error,
  min,
  max,
  onChange,
  onBlur,
  prefix,
  containerClassName,
  // ...rest
}) => {
  return (
    <div className={containerClassName}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="relative mt-1 rounded-md shadow-sm">
        {prefix && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-sm pointer-events-none">
            {prefix}
          </div>
        )}
        <input
          type="number"
          name={name}
          id={name}
          className={`block w-full pr-2 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 ${
            prefix ? "pl-12" : ""
          } ${
            error ? "focus:ring-red-500 focus:border-red-500" : ""
          } sm:text-sm`}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          min={min}
          max={max}
          // {...rest}
        />
        {/* <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="currency" className="sr-only">
            Currency
          </label>
          <select
            id="currency"
            name="currency"
            className="h-full py-0 pl-2 text-gray-500 bg-transparent border-transparent rounded-md focus:ring-indigo-500 focus:border-indigo-500 pr-7 sm:text-sm"
          >
            <option>USD</option>
            <option>CAD</option>
            <option>EUR</option>
          </select>
        </div> */}
      </div>
      {error && <span className="mt-1 text-xs text-red-500">{error}</span>}
      {description && !error && (
        <p className="mt-1 text-xs text-gray-500">{description}</p>
      )}
    </div>
  );
};

InputNumber.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  min: PropTypes.string,
  max: PropTypes.string,
  containerClassName: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  prefix: PropTypes.node,
};
InputNumber.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  description: "",
  value: "",
  min: "0",
  containerClassName: "",
  // error: "",
  // min: "250",
  onBlur: () => {},
  onChange: () => {},
  //   prefix: <span className="text-gray-500 sm:text-sm">$</span>
};

export default InputNumber;
