import { Link } from "react-router-dom";

const Error404 = ({ location }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full text-white bg-gray-900 h-80">
      <p className="tracking-wider">
        <span className="font-medium text-primary-500">Error 404</span> - Página no encontrada - La ruta{" "}
        <code className="p-1 text-sm rounded-md text-primary-500 bg-primary-900">{location.pathname}</code>, no existe en este sitio.
      </p>
      <Link to="/" exact="true">
        <button className="w-full px-8 py-4 mt-5 tracking-wide text-white uppercase bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700">
          Volver al home
        </button>
      </Link>
    </div>
  );
};

export default Error404;
