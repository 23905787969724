import { lazy, Suspense, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Footer, Header, Loader, ScrollTop } from "components";
import Error404 from "pages/Error404";
import FirebaseProvider from "firebase";
const Home = lazy(() => import("pages/Home"));
const Register = lazy(() => import("pages/Register"));

function App() {
  const scrollRef = useRef(null);
  return (
    <FirebaseProvider>
      <Router>
        <ScrollTop />
        <Header scrollRef={scrollRef} />
        <Suspense fallback={<Loader />}>
          <main>
            <Switch>
              <Route exact path="/">
                <Home scrollRef={scrollRef} />
              </Route>
              <Route exact path="/registro">
                <Register />
              </Route>
              <Route path="*" component={Error404} />
            </Switch>
          </main>
        </Suspense>
        <Footer />
      </Router>
    </FirebaseProvider>
  );
}

export default App;
