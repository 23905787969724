import heroImage from "assets/img/crps-1.png";
import HeroContent from "./HeroContent";
import { useLocation } from "react-router-dom";

const HeroImage = ({ children, scrollAction }) => {
  const { pathname } = useLocation();
  const isHome = Boolean(pathname.trim() === "/");
  return (
    <div
      className={`w-full ${
        isHome ? "h-screen" : "h-80"
      } bg-scroll bg-center bg-no-repeat bg-cover`}
      style={{
        backgroundImage: `url(${heroImage})`,
      }}
    >
      <div
        className="z-10 flex items-center justify-center w-full h-full"
        style={{
          background: "rgba(0, 0, 0, 0.3)",
        }}
      >
        <HeroContent
          title={isHome ? "Jornadas\n" : ""}
          titleColor={
            isHome
              ? "Desafíos de la Salud Mental en Pandemia. Innovando en Telesalud"
              : pathname.replace("/", "").toUpperCase()
          }
          description={
            isHome
              ? "11 y 12 de noviembre 2021. Organizado por CRPs UACh, Valdivia."
              : ""
          }
          btnText={isHome ? "Ver más" : ""}
          btnAction={isHome ? scrollAction : () => {}}
        />
        {children}
      </div>
    </div>
  );
};

export default HeroImage;
