const SystematizationAbout = () => {
  return (
    <div className="tracking-wide text-gray-700">
      <h2 className="text-xl font-medium md:text-2xl">
        Experiencias del trabajo o intervención telemática:
      </h2>
      <hr className="mt-2 mb-4" />
      <p className="mb-2 text-justify">
        - Se espera recibir trabajos que sistematicen las experiencias de
        telesalud llevadas a cabo en el trabajo de salud mental durante la
        pandemia por COVID-19.
      </p>
      <p className="mb-2 text-justify">
        - Los trabajos seleccionados deberán ser presentados en plataforma Zoom
        durante las Jornadas.
      </p>
      <p className="mb-2 text-justify">
        - Las presentaciones tendrán una duración máxima de 15 minutos y habrá
        espacio para que luego se realicen preguntas. Además, serán transmitidos
        por canal de youtube de la Facultad de Medicina de la Universidad
        Austral de Chile.
      </p>
      <p className="mb-2 text-justify text-primary-500">
        - Enviar el formulario completo a crps@uach.cl. antes del 28 de octubre
        2021.
      </p>
    </div>
  );
};

export default SystematizationAbout;
