import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Inquiries from "./Inquiries";
import WhereWhen from "./WhereWhen";

const About = () => {
	return (
		<>
			<div className="tracking-wide text-white bg-gray-900">
				<LazyLoad throttle={200} height={300}>
					<CSSTransition
						key={"About"}
						in={true}
						timeout={500}
						classNames="item"
					>
						<div className="container px-3 py-10 mx-auto md:py-16 md:px-10 md:max-w-5xl">
							<h1 className="text-3xl font-medium">Sobre el evento</h1>
							<hr className="mt-2 border-t-2"/>
							<div className="font-light prose lg:prose-xl text-white mt-5">
								<p className="text-justify">
									Debido al Covid-19, quienes trabajamos en el área de la salud
									mental, hemos visto la necesidad de mudar gran parte de nuestras
									acciones al entorno virtual, que es donde actualmente ocurren
									las interacciones sociales que pueden brindar acompañamiento y
									contención.
								</p>
								<p className="text-justify">
									Por lo tanto, los invitamos a este espacio para compartir las
									estrategias telematicas positivas que han contribuido al
									bienestar de la población usuaria de la red de servicios de
									salud mental.
								</p>
								<p className="italic">* Actividad sin costo.</p>
								<p className="italic">
									* Inscripciones para los expositores hasta el 28 de octubre
									2021.
								</p>
								<p className="italic">
									* Inscripciones para asistentes hasta el 10 de noviembre 2021.
								</p>
							</div>
							<div className="w-full pb-4 mt-5 md:mt-7">
								<Link to="/registro">
									<button
										className="w-full px-8 py-4 tracking-wide text-white uppercase bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700">
										Inscríbete aquí
									</button>
								</Link>
							</div>
						</div>
					</CSSTransition>
				</LazyLoad>
			</div>
			<WhereWhen/>
			<Inquiries/>
		</>
	);
};

export default About;
