import app from "firebase/compat/app";
import "firebase/compat/firestore";
import firebaseConfig from "./config";
// import { getAnalytics } from "firebase/analytics";
// export const analytics = getAnalytics();

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(firebaseConfig);
    }
    this.db = app.firestore();
  }

  // db() {
  //   return app.firestore();
  // }
}

const firebase = new Firebase();
export default firebase;
