function BiCalendarAlt(props) {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      {...props}
    >
      <path fill="none" d="M4.998 8H19V20H4.998z" />
      <path d="M2.999,6v2v12c0,1.103,0.897,2,2,2h14c1.103,0,2-0.897,2-2V8V6c0-1.103-0.897-2-2-2h-2V2h-2v2h-6V2h-2v2h-2 C3.896,4,2.999,4.897,2.999,6z M19.001,20H4.999V8h14L19.001,20z" />
    </svg>
  );
}

export default BiCalendarAlt;
