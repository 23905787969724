import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Logo = ({ logo, alt, containerClassName, logoClassName }) => {
  return (
    <Link exact="true" to="/">
      <div className={containerClassName}>
        <img className={logoClassName} src={logo} alt={`logo-${alt}`} />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  alt: PropTypes.string,
  containerClassName: PropTypes.string,
  logoClassName: PropTypes.string,
  logo: PropTypes.any,
};
Logo.defaultProps = {
  alt: "",
  containerClassName: "",
  logoClassName: "",
  logo: "",
};

export default Logo;
