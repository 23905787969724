import { ImSpinner2 } from "components/Icons";
import PropTypes from "prop-types";

const RutInput = ({
  label,
  name,
  type,
  placeholder,
  error,
  value,
  maxLength,
  onChange,
  onBlur,
  containerClassName,
  index,
  parentName,
  isLoading,
}) => {
  const handleChange = (e) => {
    if (index >= 0) {
      onChange(parentName, index, e.target.name, e.target.value);
    } else {
      onChange(e.target.name, e.target.value);
    }
  };
  return (
    <div className={containerClassName}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type={type}
          name={name}
          id={name}
          className={`block mt-1 shadow-sm w-full pr-12 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
        ${error ? "focus:ring-red-500 focus:border-red-500" : ""}`}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          maxLength={maxLength}
        />
        {isLoading && (
          <div className="absolute inset-y-0 right-0 flex items-center">
            <span className="flex items-center justify-center h-full px-3 py-0 text-gray-500 bg-transparent">
              <ImSpinner2 className="text-lg animate-spin" />
            </span>
          </div>
        )}
      </div>
      {error && <span className="mt-1 text-xs text-red-500">{error}</span>}
    </div>
  );
};

RutInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  maxLength: PropTypes.string,
  containerClassName: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  index: PropTypes.number,
  parentName: PropTypes.string,
};
RutInput.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  value: "",
  containerClassName: "",
  type: "text",
  // error: "",
  onBlur: () => {},
  onChange: () => {},
};

export default RutInput;
