import { FormTitle, Input, InputNumber, Textarea } from "components/UI";

const WorkshopStep = ({ onChange, handleChange, values, errors }) => {
  return (
    <div className="p-2 rounded-lg lg:p-4 xl:p-6 bg-gray-50">
      <FormTitle title="3.- Descripción del taller:" />
      <Input
        label="Objetivo"
        onChange={onChange}
        name="workshop_objective"
        value={values.workshop_objective}
        error={errors.workshop_objective}
      />

      <legend className="mt-3 mb-1 text-sm text-gray-800">
        - A quién fue dirigida la experiencia:
      </legend>
      <div className="grid grid-cols-1 gap-1 md:gap-3 md:grid-cols-2 lg:grid-cols-3">
        <Input
          label="Grupo objetivo"
          onChange={onChange}
          name="workshop_objective_group"
          value={values.workshop_objective_group}
          error={errors.workshop_objective_group}
        />
        <Input
          label="Ciclo vital"
          onChange={onChange}
          name="workshop_life_cycle"
          value={values.workshop_life_cycle}
          error={errors.workshop_life_cycle}
        />
        <InputNumber
          label="Número de participantes"
          onChange={onChange}
          name="workshop_number_of_participants"
          value={values.workshop_number_of_participants}
          error={errors.workshop_number_of_participants}
          min="0"
          max="30"
        />
      </div>
      <div className="grid grid-cols-1 gap-1 md:gap-3 2xl:grid-cols-2">
        <Textarea
          containerClassName="mt-2"
          label="Actividades que se realizarán"
          description="Describir cada una de las actividades que se desarrollarán en el taller (Máximo total  ½ plana)."
          onChange={onChange}
          name="workshop_activities_to_be_performed"
          value={values.workshop_activities_to_be_performed}
          error={errors.workshop_activities_to_be_performed}
          rows="5"
          maxLength="1700"
        />
        <Textarea
          containerClassName="mt-2"
          label="Recursos necesarios"
          description="Describir qué recursos se necesitarán para llevar a cabo el taller (Máximo 10 líneas)."
          onChange={onChange}
          name="workshop_necessary_resources"
          value={values.workshop_necessary_resources}
          error={errors.workshop_necessary_resources}
          rows="5"
          maxLength="600"
        />
      </div>
    </div>
  );
};

export default WorkshopStep;
