import { AiOutlineUserAdd, HiOutlineUserRemove } from "components/Icons";
import { FormTitle, Input } from "components/UI";
import { Fragment } from "react";

const InstitutionStep = ({
  handleChange,
  values,
  errors,
  handleDynamicChange,
  addFormFields,
  removeFormFields,
}) => {
  const handleNewFields = () => {
    addFormFields("authors", {
      author_fullname: "",
      author_email: "",
      author_phone: "",
    });
  };
  const totalAuthors = values?.authors?.length;
  return (
    <div className="p-2 rounded-lg lg:p-4 xl:p-6 bg-gray-50">
      <FormTitle title="2.- Identificación de la Organización o Institución que postula:" />
      <Input
        label="Nombre de la Institución/Organización que propone el trabajo de sistematización"
        onChange={handleChange}
        name="institution_name"
        value={values.institution_name}
        error={errors.institution_name}
      />
      <legend className="mt-3 mb-1 text-sm text-gray-800">
        Localidad donde se ubica la Institución/Organización proponente:
      </legend>
      <div className="grid grid-cols-1 gap-1 md:gap-3 md:grid-cols-2 lg:grid-cols-3">
        <Input
          label="País"
          onChange={handleChange}
          name="institution_country"
          value={values.institution_country}
          error={errors.institution_country}
        />
        <Input
          label="Región"
          onChange={handleChange}
          name="institution_region"
          value={values.institution_region}
          error={errors.institution_region}
        />
        <Input
          label="Comuna"
          onChange={handleChange}
          name="institution_commune"
          value={values.institution_commune}
          error={errors.institution_commune}
        />
        <Input
          label="Dirección"
          onChange={handleChange}
          name="institution_address"
          value={values.institution_address}
        />
      </div>

      <FormTitle
        title="Datos de autora/autor (es) de la sistematización"
        description="Si hay mas de 1 autora/autor, puedes agregar hasta 3."
        className="mt-2"
      />
      <div className="flex justify-end mb-2">
        <button
          className="flex flex-row items-center justify-between px-3 py-2 text-xs tracking-wide text-green-700 bg-green-100 rounded-md shadow-sm hover:bg-green-200"
          type="button"
          onClick={handleNewFields}
        >
          <AiOutlineUserAdd className="mr-2 text-base" /> Agregar autora/autor
        </button>
      </div>
      {values?.authors?.map((author, index) => (
        <Fragment key={index}>
          {totalAuthors > 1 ? (
            <span className="text-sm text-gray-800">{`- Autora/autor nº ${
              index + 1
            }:`}</span>
          ) : null}
          <div className="grid grid-cols-1 gap-1 md:gap-3 md:grid-cols-2 lg:grid-cols-3">
            <Input
              label="Nombre completo"
              onChange={handleDynamicChange}
              name="author_fullname"
              value={author.author_fullname}
              error={errors?.authors?.map((p, i) =>
                p.id === index + 1 ? p.author_fullname : null
              )}
              index={index}
              parentName="authors"
            />
            <Input
              label="Correo electrónico"
              type="email"
              onChange={handleDynamicChange}
              name="author_email"
              value={author.author_email}
              error={errors?.authors?.map((p, i) =>
                p.id === index + 1 ? p.author_email : null
              )}
              index={index}
              parentName="authors"
            />
            <Input
              label="Teléfono"
              onChange={handleDynamicChange}
              name="author_phone"
              prefix={<span>+56</span>}
              maxLength="9"
              value={author.author_phone}
              error={errors?.authors?.map((p, i) =>
                p.id === index + 1 ? p.author_phone : null
              )}
              index={index}
              parentName="authors"
            />
          </div>
          {index ? (
            <div
              className={`flex justify-start${
                totalAuthors === index + 1 ? " mt-3 mb-8" : " my-3"
              }`}
            >
              <button
                className="flex flex-row items-center justify-between px-3 py-2 text-xs tracking-wide text-red-700 bg-red-100 rounded-md shadow-sm hover:bg-red-200"
                type="button"
                onClick={() =>
                  removeFormFields("authors", index, values?.authors)
                }
              >
                <HiOutlineUserRemove className="mr-2 text-base" /> Remover
                autora/autor {index + 1}
              </button>
            </div>
          ) : null}
        </Fragment>
      ))}
    </div>
  );
};

export default InstitutionStep;
