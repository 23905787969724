import PropTypes from "prop-types";

const Textarea = ({
  label,
  name,
  description,
  placeholder,
  value,
  error,
  containerClassName,
  onChange,
  maxLength,
  rows,
  index,
  parentName,
}) => {
  const handleChange = (e) => {
    if (index >= 0) {
      onChange(parentName, index, e.target.name, e.target.value);
    } else {
      onChange(e);
    }
  };
  return (
    <div className={containerClassName}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="mt-1">
        <textarea
          id={name}
          name={name}
          rows={rows}
          className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onChange={handleChange}
        />
      </div>
      {error && <span className="mt-1 text-xs text-red-500">{error}</span>}
      {description && !error && (
        <p className="mt-1 text-xs text-gray-500">{description}</p>
      )}
    </div>
  );
};

Textarea.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  containerClassName: PropTypes.string,
  onChange: PropTypes.func,
  index: PropTypes.number,
  parentName: PropTypes.string,
};
Textarea.defaultProps = {
  description: "",
  name: "",
  label: "",
  placeholder: "",
  value: "",
  // error: "",
  containerClassName: "",
  onChange: () => {},
};
export default Textarea;
