export const PARTICIPANTS_TYPE = {
	systematization_assistant: "Asistente a Sistematización",
	systematization_exhibitor: "Expositor de Sistematización",
	telematics_workshop_exhibitor: "Expositor de Taller Telemático",
};
export const PARTICIPANTS_TYPE_OPTIONS = [
	{
		value: "systematization_assistant",
		name: "Asistente a Sistematización",
	},
	{
		value: "systematization_exhibitor",
		name: "Expositor de Sistematización",
	},
	{
		value: "telematics_workshop_exhibitor",
		name: "Expositor de Taller Telemático",
	},
];


export const dayOne = [
	{
		time: "9:00 - 9:15",
		program: "Bienvenida",
		isBold: false,
		icon: false,
	},
	{
		time: "9:15 - 10:00",
		program: "Línea Esperanza",
		isBold: true,
		icon: false,
	},
	{
		time: "10:00 - 10:45",
		program: "Cultivando al cuidador",
		isBold: true,
		icon: false
	},
	{
		time: "10:45 - 11:15",
		program: "Mesa de diálogo 1",
		isBold: false,
		icon: false
	},
	{
		time: "11:15 - 11:30",
		program: "BREAK",
		isBold: false,
		icon: true
	},
	{
		time: "11:30 - 12:15",
		program: "Experiencias telemáticas para adultos mayores del PADAM HdC",
		isBold: true,
		icon: false
	},
	{
		time: "12:15 – 13:00",
		program: "Nadie se salva solo, nadie salva a nadie, nos salvamos en comunidad",
		isBold: true,
		icon: false
	},
	{
		time: "13:00 - 13:30",
		program: "Mesa de diálogo 2",
		isBold: false,
		icon: false
	},
	{
		time: "13:30 - 15:00",
		program: "ALMUERZO",
		isBold: false,
		icon: true
	},
	{
		time: "15:00 - 16:00",
		program: "TALLER: Expresión emocional",
		isBold: false,
		icon: false
	},
]
export const dayTwo = [
	{
		time: "9:00 - 9:45",
		program: "Colaboración Internacional Chile-España: Experiencia de Internado virtual Terapia Ocupacional",
		isBold: true,
		icon: false,
	},
	{
		time: "9:45 - 10:30",
		program: "Docencia en salud mental en virtualidad, Universidad de la República",
		isBold: true,
		icon: false,
	},
	{
		time: "10:30 - 11:00",
		program: "Mesa de diálogo 3",
		isBold: false,
		icon: false
	},
	{
		time: "11:00 - 11:15",
		program: "BREAK",
		isBold: false,
		icon: true
	},
	{
		time: "11:15 - 12:00",
		program: "Experiencia de Promoción de Salud con grupos consultivos, CAPs UACh SPM 2020 2021",
		isBold: true,
		icon: false
	},
	{
		time: "12:00 - 12:45",
		program: "Centro de Rehabilitación Psicosocial en pandemia: adaptaciones, desafíos, y aprendizajes",
		isBold: true,
		icon: false
	},
	{
		time: "12:45 - 13:15",
		program: "Mesa de diálogo 4",
		isBold: false,
		icon: false
	},
	{
		time: "13:15 - 13:30",
		program: "Cierre exposiciones",
		isBold: false,
		icon: false
	},
	{
		time: "13:30 - 15:00",
		program: "ALMUERZO",
		isBold: false,
		icon: true
	},
	{
		time: "15:00 - 16:00",
		program: "TALLER: ¿Cómo me cuido?",
		isBold: false,
		icon: false
	},
]