import {
  Datepicker,
  FormTitle,
  Input,
  InputNumber,
  Textarea,
} from "components/UI";

const ExperienceStep = ({ onChange, handleChange, values, errors }) => {
  return (
    <div className="p-2 rounded-lg lg:p-4 xl:p-6 bg-gray-50">
      <FormTitle title="3.- Descripción de la Experiencia:" />
      <Input
        label="Objetivo"
        onChange={onChange}
        name="experience_objective"
        value={values.experience_objective}
        error={errors.experience_objective}
      />

      <legend className="mt-3 mb-1 text-sm text-gray-800">
        A quién fue dirigida la experiencia:
      </legend>
      <div className="grid grid-cols-1 gap-1 md:gap-3 md:grid-cols-2 lg:grid-cols-3">
        <Input
          label="Grupo objetivo"
          onChange={onChange}
          name="experience_objective_group"
          value={values.experience_objective_group}
          error={errors.experience_objective_group}
        />
        <Input
          label="Ciclo vital"
          onChange={onChange}
          name="experience_life_cycle"
          value={values.experience_life_cycle}
          error={errors.experience_life_cycle}
        />
        <InputNumber
          label="Número de participantes"
          onChange={onChange}
          name="experience_number_of_participants"
          value={values.experience_number_of_participants}
          error={errors.experience_objective}
          min="0"
          max="30"
        />
      </div>
      <legend className="mt-3 mb-1 text-sm text-gray-800">
        Fechas en la que se llevó a cabo:
      </legend>
      <div className="grid grid-cols-1 gap-1 mt-2 md:gap-3 md:grid-cols-2 lg:grid-cols-3">
        <Datepicker
          label="Fecha de inicio"
          onChange={handleChange}
          name="experience_date_start"
          value={values.experience_date_start}
          error={errors.experience_date_start}
          type="date"
        />
        <Datepicker
          label="Fecha de termino"
          onChange={handleChange}
          name="experience_date_end"
          value={values.experience_date_end}
          error={errors.experience_date_end}
          type="date"
        />
      </div>
      <div className="grid grid-cols-1 gap-1 mt-2 md:gap-3 2xl:grid-cols-2">
        <Textarea
          containerClassName="mt-2"
          label="Actividades realizadas"
          description="Describir cada una de las actividades que se desarrollaron y qué recursos se utilizaron (Máximo  ½ plana)."
          onChange={onChange}
          name="experience_performed_activities"
          value={values.experience_performed_activities}
          error={errors.experience_performed_activities}
          rows="5"
          maxLength="1700"
        />
        <Textarea
          containerClassName="mt-2"
          label="Resultados de la Experiencia"
          description="Explique qué se logró con la experiencia y cuales son los verificadores de los resultados positivos. (Máximo 10 lineas)."
          onChange={onChange}
          name="experience_results"
          value={values.experience_results}
          error={errors.experience_results}
          rows="5"
          maxLength="600"
        />
      </div>
    </div>
  );
};

export default ExperienceStep;
