import PropTypes from "prop-types";
import { IoIosArrowDown } from "components/Icons";

const HeroContent = ({
  title,
  titleColor,
  description,
  btnText,
  btnAction,
}) => {
  return (
    <div className="flex flex-col items-center justify-center px-3 whitespace-pre-wrap mt-28 md:mt-20 md:px-10">
      <h1 className="text-4xl font-medium text-center text-white uppercase md:text-7xl">
        {title}
        {titleColor && (
          <span className="normal-case text-primary-500">{titleColor}</span>
        )}
      </h1>
      {description && (
        <span className="px-2 mt-3 text-gray-300 md:text-xl md:px-0">
          {description}
        </span>
      )}
      {btnText && (
        <button
          onClick={btnAction}
          className="flex flex-row items-center justify-between px-5 py-2 mt-8 tracking-wide text-white uppercase bg-indigo-600 shadow-sm md:px-8 md:py-3 rounded-3xl hover:bg-indigo-700"
        >
          {btnText} <IoIosArrowDown className="ml-2" />
        </button>
      )}
    </div>
  );
};

HeroContent.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  description: PropTypes.string,
  btnText: PropTypes.string,
  btnAction: PropTypes.func,
};

HeroContent.defaultProps = {
  title: "",
  titleColor: "",
  description: "",
  btnText: "",
  btnAction: () => {},
  // btnText: "Inscríbete aquí",
};

export default HeroContent;
