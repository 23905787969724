import PropTypes from "prop-types";

const FormTitle = ({ title, description, className }) => {
  return (
    <div className={`mb-2 ${className ? className : ""}`}>
      {title && (
        <legend className="text-base font-medium text-gray-800">{title}</legend>
      )}
      {description && (
        <p className="text-xs text-gray-500 xl:text-sm">{description}</p>
      )}
    </div>
  );
};

FormTitle.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};
FormTitle.defaultProps = {
  description: "",
  title: "",
  className: "",
};

export default FormTitle;
